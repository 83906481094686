import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=462d023a&scoped=true"
import script from "./edit.vue?vue&type=script&lang=js"
export * from "./edit.vue?vue&type=script&lang=js"
import style0 from "./edit.vue?vue&type=style&index=0&id=462d023a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462d023a",
  null
  
)

export default component.exports