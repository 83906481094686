<template>
    <div class="activity">
        <div class="top-title"><i class="el-icon-info">(以下所填信息，为上一年度数据)</i></div>
        <div class="top">
            <div class="title">经营活动</div>
            <div class="activity-label">
                <div v-for="(item,index) in activity" :key="index">
                    <span>{{item.title}}</span>
                    <i :class="activityLabel[index] == '1'?'el-icon-circle-check':'el-icon-circle-close'" :style="activityLabel[index] == '1'?'color:green':'gray'"></i>
                </div>
            </div>
        </div>
        <div class="middle">
            <div class="title">国际贸易</div>
            <div v-for="(item,index) in trade" :key="index" style="margin-bottom:20px">
                <span style="margin-right:20px">{{item.title}}</span>
                <span>{{tradeLabel[index]}}万美元</span>
            </div>
        </div>
        <div class="footer">
            <div class="title">服务外包</div>
            <div v-for="(item,index) in outsourcing" :key="index" style="margin-bottom:20px">
                <span style="margin-right:20px">{{item.title}}</span>
                <span>{{outsourcingLabel[index]}}万美元</span>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from '../../../../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'activity',
  data () {
    return {
      activity: [],
      activityLabel: [],
      trade: [],
      tradeLabel: [],
      outsourcing: [],
      outsourcingLabel: []
    };
  },
  mounted () {
    this.getFindInternationalTrade('');
    this.getFindInternationalTrade(getEntityId());
  },
  methods: {
    getFindInternationalTrade (entityId) {
      const that = this;
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findInternationalTrade',
        params: {
          entityId: entityId
        }
      })
        .then(res => {
          if (res.data.code === 200) {
            if (!entityId) {
              that.activity = res.data.data.operatingActivities;
              that.trade = res.data.data.internationalTrade;
              that.outsourcing = res.data.data.serviceOutsourcing;
            } else {
              that.activityLabel = res.data.data.internationalTrade;
              that.tradeLabel = res.data.data.operatingActivities;
              that.outsourcingLabel = res.data.data.serviceOutsourcing;
            }
          }
        })
        .catch(err => { console.log(err); });
    }
  }
};
</script>

<style lang="less" scoped>
    .activity{
        height: 100%;
        .top-title{
            background: #eee;
            padding: 10px 0;
            color: #666;
            padding-left: 20px;
            border-radius: 5px;
        }
        .activity-label{
            display: flex;
            // justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            font-size: 16px;
            >div{
                width: 25%;
                margin-bottom: 20px;
                span{
                margin-right: 20px;
            }
            i{
                font-size: 24px;
            }
            }
        }
    }
    .title{
         color: #156ED0;
        font-size: 16px;
        font-weight: bold;
        margin: 30px 0;
    }
</style>
