<template>
  <div class="knowledge">
    <div class="top-title">
      <i class="el-icon-info">(以下所填信息，为上一年度数据)</i>
    </div>
    <div class="content">
      <div class="top">
        <div class="title">专利</div>
        <div
          v-for="(item, index) in patent"
          :key="index"
          style="margin-bottom: 20px"
          class="m-box"
        >
          <div class="s-box">{{ item.title }}</div>
          <div class="s-box">{{ patentLabel[index] }}个</div>
        </div>
      </div>
      <div class="middle">
        <div class="title">商标</div>
        <div
          v-for="(item, index) in trademark"
          :key="index"
          style="margin-bottom: 20px"
          class="m-box"
        >
          <div class="s-box">{{ item.title }}</div>
          <div class="s-box">{{ trademarkLabel[index] }}个</div>
        </div>
      </div>
      <div class="footer">
        <div class="title">著作权</div>
        <div
          v-for="(item, index) in copyright"
          :key="index"
          style="margin-bottom: 20px"
          class="m-box"
        >
          <div class="s-box">{{ item.title }}</div>
          <div class="s-box">{{ copyrightLabel[index] }}个</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'knowledge',
  data () {
    return {
      patent: [],
      trademark: [],
      copyright: [],
      patentLabel: [],
      trademarkLabel: [],
      copyrightLabel: []
    };
  },
  mounted () {
    this.getFindIntellectualProperty('');
    this.getFindIntellectualProperty(getEntityId());
  },
  methods: {
    getFindIntellectualProperty (entityId) {
      const that = this;
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findIntellectualProperty',
        params: {
          entityId: entityId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            if (!entityId) {
              that.patent = res.data.data.patent;
              that.trademark = res.data.data.trademark;
              that.copyright = res.data.data.copyright;
            } else {
              that.patentLabel = res.data.data.patent;
              that.trademarkLabel = res.data.data.trademark;
              that.copyrightLabel = res.data.data.copyright;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.knowledge {
  .top-title {
    background: #eee;
    padding: 10px 0;
    padding-left: 20px;
    border-radius: 5px;
    font-size: 16px;
  }
}
.title {
  color: #156ED0;
  font-size: 16px;
  font-weight: bold;
  margin: 30px 0;
}

.m-box{
   display: flex;
}
.s-box{
    width: 30%;
}

</style>
