<template>
  <div class="edit">
    <div class="title">自定义标签</div>
    <div class="selected-lable">
      <div>已选标签</div>
      <div>
        <el-tag v-for="item in selectedList" :key="item.id" closable @close="handleCloseSelect(item)">
          {{ item.name }}
        </el-tag>
      </div>
    </div>
    <div class="all-lable">
      <div>全部标签</div>
      <div>
        <el-tag
          :key="index"
          v-for="(tag, index) in dynamicTags"
          closable
          :disable-transitions="false"
          :effect="!tag.status ? 'plain' : 'light'"
          @close="handleClose(tag)"
          @click="handleClick(tag, index)"
          style="margin-bottom:10px"
        >
          {{ tag.name }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput"
          >+ 添加</el-button
        >
      </div>
    </div>
    <el-divider></el-divider>
    <div class="title" style="margin-bottom: 20px">备注</div>
    <el-input type="textarea" placeholder="请输入备注内容" v-model="remark">
    </el-input>
    <div style="text-align:center;margin-top:50px"><el-button type="primary" @click="submit">保存</el-button></div>
  </div>
</template>

<script>
import { request } from '../../../../../../../network';
export default {
  name: 'edit',
  data () {
    return {
      selectedList: [],
      dynamicTags: [],
      remark: '',
      inputVisible: false,
      inputValue: ''
    };
  },
  mounted () {
    this.getAllCustomTags();
    this.getAllCustomLabels();
  },
  watch: {
    inputValue (val) {

    }
  },
  methods: {
    getAllCustomTags () {
      const that = this;
      request({
        method: 'POST',
        url: '/pcp/org/getcustomerlabelibraryl',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.forEach((v) => {
              v.status = false;
            });
            that.dynamicTags = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllCustomLabels () {
      request({
        method: 'POST',
        url: '/pcp/org/allCustomTags',
        data: {
          customerId: localStorage.getItem('label_id'),
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      }).then(res => {
        if (res.data.code === 200) {
          if (res.data.data instanceof Array) {
            this.selectedList = res.data.data;
          } else {
            this.selectedList = [];
          }
        }
      }).catch(err => { console.log(err); });
    },
    handleCloseSelect (item) {
      request({
        method: 'POST',
        url: '/pcp/org/deleteCustomsTag',
        data: {
          id: item.id,
          memberId: JSON.parse(localStorage.getItem('userinfo')).id
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: '删除已选标签成功',
            type: 'success'
          });
          this.getAllCustomLabels();
        }
      })
        .catch(err => { console.log(err); });
    },
    handleClose (tag, index) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.deleteCustomsTag(tag.id);
    },
    deleteCustomsTag (id) {
      request({
        method: 'POST',
        url: '/pcp/org/deletecustomerlabelibraryl',
        data: {
          id: id,
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: res.data.data,
              type: 'success'
            });
            this.getAllCustomTags();
            this.getAllCustomLabels();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editCustomTags (labelName) {
      request({
        method: 'POST',
        url: '/pcp/org/addcustomerlabelibraryl',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          labelName: labelName
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: res.data.data,
              type: 'success'
            });
            // this.selectedList = this.dynamicTags.filter((v) => {
            //   return v.status == true;
            // });
            this.getAllCustomTags();
          }
        })
        .catch((err) => {
          this.$message({
            message: err.data.data,
            type: 'error'
          });
        });
    },
    showInput () {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm () {
      const inputValue = this.inputValue;
      if (inputValue) {
        if (this.dynamicTags.length < 30) {
          this.editCustomTags(inputValue);
        } else {
          this.$message({
            message: '全部标签最多能添加到30个',
            type: 'warning'
          });
        }
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleClick (tag, index) {
      this.dynamicTags[index].status = !this.dynamicTags[index].status;
      request({
        method: 'POST',
        url: '/pcp/org/addcustomerlabel',
        data: {
          customerId: localStorage.getItem('label_id'),
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          labelId: tag.id,
          labelName: tag.name,
          memberId: JSON.parse(localStorage.getItem('userinfo')).id
        }
      }).then(res => {
        if (res.data.code === 200) {
          if (this.selectedList.length <= 5) {
            this.$message({
              message: '新增自定义标签成功',
              type: 'success'
            });
          }
          this.getAllCustomLabels();
        } else {
          this.$message({
            message: '已选标签最多只能选五个',
            type: 'warning'
          });
        }
      })
        .catch(err => { console.log(err); });
    },
    submit () {
      request({
        method: 'POST',
        url: '/pcp/org/updateCustomsRemake',
        data: {
          //  id:this.$store.state.backstage.submit_id,
          id: localStorage.getItem('customer_id'),
          remake: this.remark
        }
      })
        .then(res => {
          if (res.data.code === 200) {
            this.$message({
              message: res.data.data,
              type: 'success'
            });
          }
        })
        .catch(err => { console.log(err); });
    }
  }
};
</script>

<style lang="less" scoped>
.edit {
  .selected-lable {
    margin-top: 40px;
    > div:nth-child(1) {
      margin-bottom: 20px;
    }
  }
  .all-lable {
    margin-top: 100px;
    > div:nth-child(1) {
      margin-bottom: 20px;
    }
  }
}
.title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
