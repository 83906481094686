<template>
  <div class="personnel">
    <div class="top-title">
      <i class="el-icon-info">(以下所填信息，为上一年度数据)</i>
    </div>
    <div class="top">
      <div class="title">职称</div>
      <div class="activity-label">
        <div v-for="(item, index) in professionalName" :key="index"  style="margin-bottom: 20px" class="m-box">
          <span class="s-box">{{ item.title }}</span>
          <span class="s-box">{{ professionalNameLabel[index] }}人</span>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="title">学历</div>
      <div
        v-for="(item, index) in education"
        :key="index"
        style="margin-bottom: 20px"
        class="m-box"
      >
        <span class="s-box">{{ item.title }}</span>
        <span class="s-box">{{ educationLabel[index] }}人</span>
      </div>
    </div>
    <div class="footer">
      <div class="title">研发人员</div>
      <div
        v-for="(item, index) in RDPersonnel"
        :key="index"
        style="margin-bottom: 20px"
        class="m-box"
      >
        <span class="s-box">{{ item.title }}</span>
        <span class="s-box">{{ RDPersonnelLabel[index] }}人</span>
      </div>
    </div>
     <div class="botm">
      <div class="title">社保在册员工</div>
      <div
        v-for="(item, index) in registeredEmployees"
        :key="index"
        style="margin-bottom: 20px"
        class="m-box"
      >
        <span class="s-box">{{ item.title }}</span>
        <span class="s-box">{{ registeredEmployeesLabel[index] }}人</span>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'personnel',
  data () {
    return {
      professionalName: [],
      education: [],
      RDPersonnel: [],
      registeredEmployees: [],
      professionalNameLabel: [],
      educationLabel: [],
      RDPersonnelLabel: [],
      registeredEmployeesLabel: []
    };
  },
  mounted () {
    this.getFindPersonnelStatus('');
    this.getFindPersonnelStatus(getEntityId());
  },
  methods: {
    getFindPersonnelStatus (entityId) {
      const that = this;
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findPersonnelStatus',
        params: {
          entityId: entityId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            if (!entityId) {
              that.professionalName = res.data.data.professionalName;
              that.education = res.data.data.education;
              that.RDPersonnel = res.data.data.RDPersonnel;
              that.registeredEmployees = res.data.data.registeredEmployees;
            } else {
              that.professionalNameLabel = res.data.data.professionalName;
              that.educationLabel = res.data.data.education;
              that.RDPersonnelLabel = res.data.data.RDPersonnel;
              that.registeredEmployeesLabel = res.data.data.registeredEmployees;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.personnel {
  .top-title {
    background: #eee;
    padding: 10px 0;
    padding-left: 20px;
    border-radius: 5px;
    font-size: 16px;
  }
}
.title {
  color: #156ED0;
  font-size: 16px;
  font-weight: bold;
  margin: 30px 0;
}

.m-box{
   display: flex;
}
.s-box{
    width: 30%;
}
</style>
